import "./HomePage.scss"

import React, {useState} from "react"
import {useHistory} from "react-router"
import {Helmet} from "react-helmet"
import qs from "qs"

import {MovieList, SearchForm, SortBox} from "../components/Movies"
import {getUserCountry} from "../utils/language"
import {calculateQuerystring} from "../utils/querystring"

import {useQuery} from "@apollo/client"
import {GET_MOVIE_LIST} from "../schema/queries"

import {BackTop, Button, Collapse, Spin} from "antd"
const {Panel} = Collapse


const updateQuery = (previousResult, {fetchMoreResult}) => {
  return {"getMovieList": [...previousResult.getMovieList, ...fetchMoreResult.getMovieList]}
}


const getInitialValues = (querystring) => {
  const currentYear = new Date().getFullYear()
  let values = {
    imdb_score: [6.5, 10],
    popularity: [40, 100],
    year: [1915, currentYear],
    runtime: [60, 240],
  }
  let parsed = qs.parse(querystring)

  values["imdb_score"] = parseRangeValue(parsed["imdb_score"], values["imdb_score"])
  values["popularity"] = parseRangeValue(parsed["popularity"], values["popularity"])
  values["runtime"] = parseRangeValue(parsed["runtime"], values["runtime"])
  values["year"] = parseRangeValue(parsed["year"], values["year"])
  if (values["year"][0] < 1915) values["year"][0] = 1915
  values["genre"] = parseMultipleValue(parsed["genre"], [])
  values["certificate"] = parseMultipleValue(parsed["certificate"], [])
  values["text"] = parsed["text"]
  return values
}

const parseRangeValue = (value, defaultValue) => {
  if (value === undefined) {
    return defaultValue
  } else if (value.startsWith("-")) {
    return [defaultValue[0], value.substring(1)]
  } else if (value.endsWith("-")) {
    return [value.slice(0, -1), defaultValue[1]]
  } else if (value.includes("-")) {
    return value.split("-")
  }
  return [value, value]
}


const parseMultipleValue = (value, defaultValue) => {
  if (value === undefined) {
    return defaultValue
  }
  return value.split(",")
}


const getH1Text = (initialValues) => {
  const currentYear = new Date().getFullYear()
  let genre = ""
  let year = ""
  if (initialValues["genre"].length > 0 && initialValues["genre"].length <= 2) {
    genre = " " + initialValues["genre"].join(" and ")
  }

  let minYear = parseInt(initialValues["year"][0])
  let maxYear = parseInt(initialValues["year"][1])

  if (maxYear - minYear == 9 && minYear % 10 == 0) {
    year = " of the " +  minYear + "s"
  } else if (maxYear == minYear) {
    year = " of " + maxYear
  } else if (maxYear == 1999 && minYear < 1950) {
    year = " of the 20th Century"
  } else if (minYear == 2000 && maxYear == currentYear) {
    year = " of the 21st Century"
  } else if (minYear < 1960 && maxYear == currentYear) {
    year = " of All Time"
  } else if (minYear == 2020 && maxYear == currentYear) {
    year = " of the " +  minYear + "s"
  }

  return "Best" + genre + " Movies" + year
}


const getPageTitle = (h1Text) => {
  if (h1Text == "Best Movies") return "Discover Great Movies"
  return h1Text
}


const getCanonicalUrl = (initialValues, pageTitle) => {
  let qsData = {...initialValues}
  delete qsData["popularity"]
  delete qsData["runtime"]
  delete qsData["imdb_score"]
  delete qsData["certificate"]
  delete qsData["text"]
  if (qsData["genre"].length > 2) {
    delete qsData["genre"]
  }
  if (!pageTitle.includes(" of ")) {
    delete qsData["year"]
  }
  const querystring = calculateQuerystring(qsData)
  if (querystring) {
    return "https://www.movieraker.com/movies?" + querystring
  }
  return "https://www.movieraker.com/movies"
}


export function HomePage() {
  const history = useHistory()
  const initialSortBy = {"field": "imdb_score", "order": "DESC"}
  const [sortBy, setSortBy] = useState(initialSortBy)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const limit = 40
  const userCountry = getUserCountry()
  const initialValues = getInitialValues(history.location.search.substring(1))
  const h1Text = getH1Text(initialValues)
  const pageTitle = getPageTitle(h1Text)
  const canonicalUrl = getCanonicalUrl(initialValues, pageTitle)
  const {loading, error, data, refetch, fetchMore} = useQuery(
    GET_MOVIE_LIST,
    {
      variables: {
        "filters": {
          "imdb_score": {"min": initialValues["imdb_score"][0], "max": initialValues["imdb_score"][1]},
          "popularity": {"min": initialValues["popularity"][0], "max": initialValues["popularity"][1]},
          "year": {"min": initialValues["year"][0], "max": initialValues["year"][1]},
          "runtime": {"min": initialValues["runtime"][0], "max": initialValues["runtime"][1]},
          "genre": initialValues["genre"],
          "certificates": initialValues["certificate"],
          "text": initialValues["text"],
        },
        "certificate_country": userCountry,
        "sort_by": initialSortBy,
        "offset": 0,
        "limit": limit,
      },
    },
  )

  if (error) return <p>Error :(</p>

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Movie Raker</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={pageTitle + " | Movie Raker"} />
      </Helmet>

      <div className="welcome-box-container">
        <div className="welcome-box">
          <h2 className="welcome-box__title">Discover Great Movies with Movie Raker!</h2>
          <p>Discover great movies - search based on rating, popularity, genre and year.</p>
          <p>Keep track of movies you've seen and create a watchlist.</p>
          <p>Add friends and find great movies that neither of you have seen.</p>
        </div>
      </div>

      <div className="site-layout-content">
        <h1>{h1Text}</h1>
        <Collapse className="expandable" defaultActiveKey={["filters"]} bordered={false} accordion={true} expandIconPosition="right">
          <Panel header={
            <h2>Filters</h2>
          } key="filters" style={{"padding": 0}}>
            <SearchForm initialValues={initialValues} refetch={refetch} limit={limit} sortBy={sortBy} />
          </Panel>
        </Collapse>

        <SortBox refetch={refetch} limit={limit} setSortBy={setSortBy} />
        {
          loading ? (
            <Spin size="large" tip="Loading" className="loading--centered" />
          ) : (
            <>
              <MovieList
                movies={data.getMovieList}
                userCountry={userCountry}
              />
              <div className="show-more__container">
                {
                  !fetchMoreLoading ? (
                    <Button
                      className="show-more__button"
                      type="primary"
                      onClick={() => {
                        setFetchMoreLoading(true)
                        fetchMore({
                          variables: {
                            sort_by: sortBy,
                            offset: data.getMovieList.length,
                            limit: limit,
                          },
                          updateQuery,
                        }).then(
                          setFetchMoreLoading(false)
                        )
                      }}
                    >Show more</Button>
                  ) : (
                    <Spin size="large" tip="Loading" className="loading--centered" />
                  )
                }
              </div>
            </>
          )
        }
        <BackTop />
      </div>
    </>
  )
}


export default HomePage
