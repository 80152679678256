import React from "react"
import {Button, Divider, Modal, Typography} from "antd"
const {Text} = Typography
import SignInWithFacebook from "../Facebook/SignInWithFacebook"


export function LoginModal(props) {
  return (
    <Modal title="Login" visible={props.isVisible} onCancel={props.onCancel} footer={<Button key="cancel" onClick={props.onCancel}>Cancel</Button>}>
      <SignInWithFacebook />
      <Divider />
      <p>Login to keep track of movies you've seen and add to your watchlist.</p>
      <p>Add friends and find great movies that neither of you have seen.</p>
      <Divider />
      <Text italic type="secondary">Other login methods coming soon...</Text>
    </Modal>
  )
}


export default LoginModal
