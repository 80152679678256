import React from "react"
import {Auth} from "aws-amplify"

import "./SignInWithFacebook.scss"
import FacebookLogo from "../../images/facebook.svg"


export function SignInWithFacebook(props) {
  const signIn = () => {
    window.FB.getLoginStatus(response => {
      if (response.status === "connected") {
        Auth.federatedSignIn({provider: "Facebook"})
      } else {
        window.FB.login(response => {
          if (!response || !response.authResponse) {
            return
          }
          Auth.federatedSignIn({provider: "Facebook"})
        }, {
          scope: "public_profile,email,user_friends"
        })
      }
    })

  }

  return (
    <div
      className="facebook-button"
      onClick={signIn}
    >
      <div className={props.iconClassName ? props.iconClassName : "facebook-button__icon"}><img src={FacebookLogo} width={32} /></div>
      Login with Facebook
    </div>
  )
}


export default SignInWithFacebook
