import React, {useState} from "react"

import {Helmet} from "react-helmet"
import {useQuery} from "@apollo/client"
import {Button, Input, Modal, Spin, Tooltip} from "antd"
import {BarChartOutlined} from "@ant-design/icons"
import stringSimilarity from "string-similarity"
import {GET_DAILY_MOVIE} from "../schema/queries"

import PlaceholderImage from "../images/placeholder.png"

import "./Blockbusterdle.scss"


export function getReadableRuntime(totalMinutes) {
  var hours = Math.floor(totalMinutes / 60)
  var minutes = totalMinutes % 60
  return hours + "h" + ("00" + minutes).slice(-2)
}


export function Blockbusterdle() {
  const localDate = new Date().toLocaleDateString("sv")
  const guessData = JSON.parse(window.localStorage.getItem(
    "blockbusterdle:guesses",
  ))
  let stats = JSON.parse(window.localStorage.getItem(
    "blockbusterdle:stats",
  ))
  if (stats == null) {
    stats = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, "Fail": 0}
  }
  const highestGuessCount = Math.max(...Object.values(stats))


  let initialGuesses = []
  let initialSolved = false
  if (guessData && guessData["date"] == localDate) {
    initialGuesses = guessData["guesses"]
    initialSolved = guessData["solved"]
  }
  const [currentGuess, setCurrentGuess] = useState("")
  const [guesses, setGuesses] = useState(initialGuesses)
  const [guessCount, setGuessCount] = useState(guesses.length)
  const [solved, setSolved] = useState(initialSolved)
  const [isStatsVisible, setIsStatsVisible] = useState(false)
  let todayGuessCount = 0
  if (solved) todayGuessCount = guessCount
  if (!solved && guessCount > 5) todayGuessCount = "Fail"

  const {loading, error, data} = useQuery(GET_DAILY_MOVIE, {
    variables: {
      "local_date": localDate
    },
  })

  if (loading || error) {
    return <Spin size="large" tip="Loading" className="loading--centered" />
  }
  if (error) {
    return <h1>Error</h1>
  }

  const movie = data.getDailyMovie
  if (movie == null) {
    return <p>No Blockbusterdle today :(</p>
  }

  const getVisibleDirectors = () => {
    let names = movie.directors.map(person => person.name)
    if (guessCount >= 2) return names
    return []
  }

  const getVisibleActors = () => {
    let names = movie.cast.map(person => person.name)
    if (guessCount == 3) return names.slice(0, 1)
    if (guessCount == 4) return names.slice(0, 2)
    if (guessCount > 4) return names
    return []
  }

  const getVisibleRuntime = () => {
    if (guessCount > 0) return getReadableRuntime(movie.runtime)
    return ""
  }

  const getVisibleGenre = () => {
    return movie.genre.join(", ")
  }

  const getVisibleYear = () => {
    if (guessCount == 1) {
      return movie.year.toString().substring(0,2) + "XX"
    } else if (guessCount == 2 || guessCount == 3) {
      return movie.year.toString().substring(0,3) + "X"
    } else if (guessCount > 3) {
      return movie.year.toString()
    }
    return ""
  }

  const increaseStats = (key) => {
    stats[key] += 1
    window.localStorage.setItem(
      "blockbusterdle:stats",
      JSON.stringify(stats)
    )
  }

  const makeGuess = (value) => {
    setGuessCount(guessCount + 1)

    let isGuessCorrect = checkForCorrectGuess(value)
    setSolved(isGuessCorrect)
    let guess = value

    if (isGuessCorrect) {
      increaseStats(guessCount + 1)
      setIsStatsVisible(true)
    } else {
      guess = addMarkupToGuess(guess)
      if (guessCount == 5) {
        increaseStats("Fail")
        setIsStatsVisible(true)
      }
    }
    setGuesses([...guesses, guess])
    setCurrentGuess("")
    window.localStorage.setItem(
      "blockbusterdle:guesses",
      JSON.stringify({
        "date": localDate,
        "guesses": [...guesses, guess],
        "solved": isGuessCorrect,
      })
    )
  }

  const checkForCorrectGuess = (value) => {
    let acceptedAnswers = movie.accepted_answers
    let normalizedAcceptedAnswers = acceptedAnswers.map(answer => normalizeString(answer))
    let results = stringSimilarity.findBestMatch(normalizeString(value), normalizedAcceptedAnswers)
    return results["bestMatch"]["rating"] > 0.85
  }

  const normalizeString = (s) => {
    s = stripTags(s)
    s = removePunctuation(s)
    s = removeCommonWords(s)
    return s
  }

  const removeCommonWords = (s) => {
    const commonWords = ["the", "and", "a", "an", "of"]
    s = s.toLowerCase()
    commonWords.forEach((word) => {
      s = s.replace(new RegExp("\\b" + word + "\\b", "gi"), "")
    })
    return s.trim().replace(/ +(?= )/g, "")
  }

  const removePunctuation = (s) => {
    s = s.trim().toLowerCase()
    s = s.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g,"")
    s = s.replace(/\s{2,}/g," ")
    return s
  }

  const stripTags = (s) => {
    return s.replace(/(<([^>]+)>)/gi, "")
  }

  const addMarkupToGuess = (guess) => {
    let correctWords = removePunctuation(movie.title).split(" ")
    let originalGuessWords = stripTags(guess).split(" ")

    let guessWords = removePunctuation(guess).split(" ")
    let output = []
    guessWords.forEach((word, index) => {
      let correctIndex = correctWords.indexOf(word)
      let originalWord = originalGuessWords[index]
      if (correctIndex !== -1) {
        correctWords.splice(correctIndex, 1)
        output.push("<span class='guess-word__correct'>" + originalWord + "</span>")
      } else {
        output.push(originalWord)
      }
    })
    return output.join(" ")
  }

  const visibleYear = getVisibleYear()
  const visibleActors = getVisibleActors()
  const visibleDirectors = getVisibleDirectors()
  const visibleRuntime = getVisibleRuntime()
  const visibleGenre = getVisibleGenre()

  return (
    <>
      <Helmet>
        <title>Blockbusterdle | Wordle for Movies</title>
        <meta property="og:title" content="Blockbusterdle | Wordle for Movies" />
        <meta property="og:url" content="https://www.movieraker.com/blockbusterdle/" />
        <meta
          property="og:description"
          content="Daily movie guessing game by Movie Raker! Guess the movie from the blurred poster. More clues will be revealed as you guess..."
        />
        <meta
          name="description"
          content="Daily movie guessing game by Movie Raker! Guess the movie from the blurred poster. More clues will be revealed as you guess..."
        />
        <meta property="og:image" content="https://movieraker.s3.eu-west-2.amazonaws.com/blockbusterdle_logo.png" />
      </Helmet>
      <div className="site-layout-content game-wrapper">
        <Tooltip title="stats" className="stats-button">
          <Button icon={<BarChartOutlined />} size="large" onClick={() => setIsStatsVisible(true)} />
        </Tooltip>
        <Modal title="Statistics" visible={isStatsVisible} onCancel={() => setIsStatsVisible(false)} footer={null}>

          <table className="stats-chart charts-css bar show-labels data-spacing-2">

            <caption>Guess distribution</caption>

            <thead>
              <tr>
                <th scope="col">Guesses</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(stats).map(([key, value], i) => (
                <tr key={i}>
                  <th scope="row">{key}</th>
                  <td style={{
                    "--size": "calc( " + value + " / " + highestGuessCount + ")",
                    "--color": key == todayGuessCount ? "#fcca23" : "#79e0fc",
                  }}>{value}</td>
                </tr>
              ))}
            </tbody>

          </table>

        </Modal>
        { solved || guessCount > 5 ? (
          <>
            <div className="game-data__poster-container">
              <img
                className="game-data__poster"
                alt="Poster"
                itemProp="image"
                src={movie.poster_image ? (
                  process.env.REACT_APP_MEDIA_URL + movie.poster_image
                ) : (
                  PlaceholderImage
                )}
              />
            </div>
            { solved ? (
              <h2 className="game-data__title game-data__title--solved">{movie.title}</h2>
            ) : (
              <h2 className="game-data__title game-data__title--failed">{movie.title}</h2>
            )}

            <dl className="game-data">
              <dt className="game-data__key">Year</dt>
              <dd className="game-data__value">{movie.year}</dd>
              <dt className="game-data__key">Runtime</dt>
              <dd className="game-data__value">{getReadableRuntime(movie.runtime)}</dd>
              <dt className="game-data__key">Genre</dt>
              <dd className="game-data__value">{movie.genre.join(", ") || "\u00A0"}</dd>
              <dt className="game-data__key">Director</dt>
              <dd className="game-data__value">{movie.directors.map(person => person.name).join(", ")}</dd>
              <dt className="game-data__key">Cast</dt>
              <dd className="game-data__value">{movie.cast.map(person => person.name).join(", ")}</dd>
            </dl>
          </>
        ) : (
          <>
            <div className="game-data__poster-container">
              <img
                className={"game-data__poster game-data__poster--blur-" + guessCount}
                alt="Poster"
                itemProp="image"
                src={movie.poster_image ? (
                  process.env.REACT_APP_MEDIA_URL + movie.poster_image
                ) : (
                  PlaceholderImage
                )}
              />
            </div>

            <dl className="game-data">
              {visibleYear ? (
                <>
                  <dt className="game-data__key">Year</dt>
                  <dd className="game-data__value">{visibleYear}</dd>
                </>
              ) : null}
              {visibleRuntime ? (
                <>
                  <dt className="game-data__key">Runtime</dt>
                  <dd className="game-data__value">{visibleRuntime}</dd>
                </>
              ) : null}
              {visibleGenre ? (
                <>
                  <dt className="game-data__key">Genre</dt>
                  <dd className="game-data__value">{visibleGenre}</dd>
                </>
              ) : null}
              {visibleDirectors.length != 0 ? (
                <>
                  <dt className="game-data__key">Director</dt>
                  <dd className="game-data__value">{visibleDirectors.join(", ")}</dd>
                </>
              ) : null}
              {visibleActors.length != 0 ? (
                <>
                  <dt className="game-data__key">Cast</dt>
                  <dd className="game-data__value">{visibleActors.join(", ")}</dd>
                </>
              ) : null}
            </dl>
          </>
        )}

        {!solved && guessCount < 6 ? (
          <div className="guess-controls">
            <Input
              className="guess-input"
              onPressEnter={(e) => makeGuess(e.target.value)}
              value={currentGuess}
              onChange={(e) => setCurrentGuess(e.target.value)}
              placeholder="Guess the movie..."
            />
            <Button className="guess-button" onClick={() => makeGuess(currentGuess)}>Guess</Button>
            <Button className="pass-button" onClick={() => makeGuess("")}>Pass</Button>
          </div>
        ) : null}

        <ul className="guess-list">
          {guesses.slice().reverse().map((guess, index) => {
            let guessClassName = "guess-list-item"
            let hasCorrectWords = guess.includes("<span")
            let isCorrectGuess = solved & (index == 0)
            if (hasCorrectWords) guessClassName += " guess-list-item__correct-words"
            if (isCorrectGuess) guessClassName += " guess-list-item__correct"
            if (guess == "") guessClassName += " guess-list-item__pass"
            return (
              <li
                key={"guess" + index}
                className={guessClassName}
                dangerouslySetInnerHTML={{__html: "<span class='guess-number'>" + (guessCount - index) + "</span><span class='guess-text'>" + guess + "</span>"}}
              />
            )
          })}
        </ul>
      </div>
    </>
  )

}
