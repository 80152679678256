import "./Navigation.scss"

import React, {useContext, useState} from "react"
import {Avatar, Drawer, Layout, List} from "antd"
import {Auth} from "aws-amplify"
import mainLogo from "../../images/movieraker.svg"
import blockbusterdleLogo from "../../images/blockbusterdle.svg"
import movierakerSquareLogo from "../../images/movieraker48.png"
import SignInWithFacebook from "../Facebook/SignInWithFacebook"

import {UserContext} from "../../auth/user"
import {NavigationItem} from "./NavigationItem"

import {LogoutOutlined, MenuOutlined, OrderedListOutlined, QuestionOutlined} from "@ant-design/icons"

const {Header} = Layout


export function Navigation() {
  const {user} = useContext(UserContext)
  const [menuVisible, setMenuVisible] = useState(false)
  let logo = <a className="logo" href="/"><img src={mainLogo} className="navbar__logo" alt="Movie Raker" height="40"/></a>
  if (window.location.pathname == "/blockbusterdle") {
    logo = <a className="logo" href="/"><img src={blockbusterdleLogo} className="navbar__logo" alt="Blockbusterdle" height="20"/></a>
  }

  const showDrawer = () => {
    setMenuVisible(true)
  }

  const onDrawerClose = () => {
    setMenuVisible(false)
  }

  async function getFBLoginStatus() {
    return new Promise((resolve) => {
      window.FB.getLoginStatus((response) => {
        resolve(response)
      })
    })
  }

  async function FBLogOut() {
    return new Promise((resolve) => {
      window.FB.logout((response) => {
        resolve(response)
      })
    })
  }

  async function signOut() {
    const loginStatusResponse = await getFBLoginStatus()

    if (loginStatusResponse.status === "connected") {
      await FBLogOut()
      await Auth.signOut()
    } else {
      await Auth.signOut()
    }
  }

  return (
    <Header>
      {logo}

      {user == null ? (
        <MenuOutlined onClick={showDrawer} style={{"fontSize": "24px", "float": "right", "lineHeight": "64px"}} />
      ) : (
        <img
          width={64}
          src={"http://graph.facebook.com/" + user.signInUserSession.idToken.payload.identities[0].userId + "/picture?type=square"}
          onClick={showDrawer}
          style={{"float": "right", "marginRight": "-20px"}}
        />
      )}

      <Drawer title="&nbsp;" className="nav-drawer" placement="right" onClose={onDrawerClose} visible={menuVisible}>
        <List>
          {user == null ? (
            [
              <List.Item
                key="facebookLogin"
                className="navbar__item navbar__item--clickable">
                <SignInWithFacebook iconClassName="navbar__icon" />
              </List.Item>,
              <NavigationItem
                key="movieraker"
                icon={<img src={movierakerSquareLogo} width="32" />}
                content={<a href='/'>Movie Raker</a>}
              />,
              <NavigationItem
                key="blockbusterdle"
                icon={<QuestionOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/blockbusterdle'>Blockbusterdle</a>}
              />,
              <NavigationItem
                key="movies-by-decade"
                icon={<OrderedListOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/movies-by-decade'>Movies by Decade</a>}
              />,
              <NavigationItem
                key="movies-by-genre"
                icon={<OrderedListOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/movies-by-genre'>Movies by Genre</a>}
              />,
            ]) : (
            [
              <NavigationItem
                key="user"
                icon={
                  <Avatar
                    shape="square"
                    size={48}
                    src={"http://graph.facebook.com/" + user.signInUserSession.idToken.payload.identities[0].userId + "/picture?type=square"}
                  />
                }
                content={user.signInUserSession.idToken.payload.name}
              />,
              <NavigationItem
                key="movieraker"
                icon={<img src={movierakerSquareLogo} width="32" />}
                content={<a href='/'>Movie Raker</a>}
              />,
              <NavigationItem
                key="blockbusterdle"
                icon={<QuestionOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/blockbusterdle'>Blockbusterdle</a>}
              />,
              <NavigationItem
                key="movies-by-decade"
                icon={<OrderedListOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/movies-by-decade'>Movies by Decade</a>}
              />,
              <NavigationItem
                key="movies-by-genre"
                icon={<OrderedListOutlined style={{"fontSize": "32px"}} />}
                content={<a href='/movies-by-genre'>Movies by Genre</a>}
              />,
              <NavigationItem
                key="logout"
                onClick={() => {signOut()}}
                icon={<LogoutOutlined style={{"fontSize": "32px"}} />}
                content="Logout"
                className="facebook-logout"
              />
            ])
          }
        </List>

      </Drawer>

    </Header>
  )
}

export default Navigation
